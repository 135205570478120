// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-employees-tsx": () => import("./../../../src/pages/employees.tsx" /* webpackChunkName: "component---src-pages-employees-tsx" */),
  "component---src-pages-english-services-tsx": () => import("./../../../src/pages/english-services.tsx" /* webpackChunkName: "component---src-pages-english-services-tsx" */),
  "component---src-pages-family-clinic-tsx": () => import("./../../../src/pages/familyClinic.tsx" /* webpackChunkName: "component---src-pages-family-clinic-tsx" */),
  "component---src-pages-guidance-tsx": () => import("./../../../src/pages/guidance.tsx" /* webpackChunkName: "component---src-pages-guidance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-psychotherapies-tsx": () => import("./../../../src/pages/psychotherapies.tsx" /* webpackChunkName: "component---src-pages-psychotherapies-tsx" */),
  "component---src-pages-qa-tsx": () => import("./../../../src/pages/qa.tsx" /* webpackChunkName: "component---src-pages-qa-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-swedish-services-tsx": () => import("./../../../src/pages/swedish-services.tsx" /* webpackChunkName: "component---src-pages-swedish-services-tsx" */),
  "component---src-pages-trainings-tsx": () => import("./../../../src/pages/trainings.tsx" /* webpackChunkName: "component---src-pages-trainings-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-current-tsx": () => import("./../../../src/templates/current.tsx" /* webpackChunkName: "component---src-templates-current-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-training-tsx": () => import("./../../../src/templates/training.tsx" /* webpackChunkName: "component---src-templates-training-tsx" */)
}

